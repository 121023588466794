import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3>{`Verdiskaping i morgendagens Hallingdal`}</h3>
    <p>{`I et lite tømmerhus på Rotneim utvikles det topp moderne programvare med hele verden som
markedspotensiale. Hvilken verdi har dette for reiselivsbransjen i vår region og
verdiskapingen i morgendagens Hallingdal? Hvilke utfordringer møter vi? Hva må legges til
rette for at vi skal få regional verdiskaping med et nedslagsfelt langt utenfor landets
grenser?`}</p>
    <h3>{`Sats på Hallingdal`}</h3>
    <p>{`Gjennom prosjektet "Sats på Hallingdal" ønsker Regionrådet å sette fokus på hvordan vi
kan styrke næringsgrunnlaget i Hallingdal. Pandemien har vist oss at reiselivet er
sårbart, men med en bredere forståelse av behov og utvikling har de satt seg et mål om
200 arbeidsplasser inne tre år. Trailguiden AS, med adresse Rotneim, utvikler
programvare og teknologi for reiselivet med hele verden som marked. I dag teller vi 5
heltidsansatte og om alt lykkes har Trailguide alene et potensiale på 40-50
arbeidsplasser innen teknologi i regionen. Men hva skal til for å komme dit?`}</p>
    <h3>{`Potensiale for mange arbeidsplasser`}</h3>
    <p>{`Utvikling av teknologi og programvare er komplekst og tar tid. Man kommer et stykke på
vei med lidenskap for faget og sene kvelder, men det kan ta flere år før man ser
inntekter av arbeidet. Derfor er det essensielt med tilgang til finansiering for å
lykkes. Vi mener helt klart at det ligger et stort potensiale i det å utvikle
arbeidsplasser innen teknologi i Hallingdal. Men for å utløse dette potensialet må
forholdene legges til rette og da snakker vi hovedsakelig om tilgang på risikokapital.`}</p>
    <h3>{`Liten historikk`}</h3>
    <p>{`Det er ingen sterk tradisjon når det kommer til å investere i teknologi i Hallingdal.
Utvikling av infrastruktur for turisme og spesielt fritidseiendom har betydd mye for
regionen, men morgendagen krever at vi får flere bein å stå på. Konkrete byggeprosjekt
der spaden allerede står i jorda forbindes med lav risiko og god gevinst i sikte for
investoren. En verdiskaping som enkelt lar seg måle og som vi er kjent med. Per i dag er
det meget god hjelp til å skape egne arbeidsplasser gjennom etablerersenteret,
næringshagen, og de kommunale næringsfondene. Dette må for all del bestå og styrkes.
Når det gjelder utvikling av programvare er saken litt mer komplisert.`}</p>
    <h3>{`Verdiskaping fra ingenting`}</h3>
    <p>{`Noen vil kanskje påstå at pizza og kaffé omgjøres til kode. Men fra spøk til alvor så
skapes det verdifull programvare ut i fra "ingenting" uten (direkte) å forbruke
naturressurser. Mens et entrepenørselskap kan låne penger til en gravemaskin, sette den
som sikkerhet og begynne å tjene penger, har ikke et programvareselskap mulighet til å
skaffe seg finansiering via banken da det ikke finnes noen fysiske produkter å stille
som sikkerhet. I programvarebransjen ser en, både nasjonalt og internasjonalt, at det er
mindre viktig hvor arbeidet utføres. Det er menneskene bak (ideéne, dyktighet og
gjennomføringsevnen) som teller, samt tilgang på kunnskap via nettet. Eksempelvis vil
jeg faktisk argumentere for at koden som blir laget her i Hallingdal er av bedre
kvalitet enn hva som lages i Silicon Valley. Det kan ligge et tosifret årsverk bak
produktet som er utviklet, men allikevel har dette uansett ikke verdi som sikkerhet for
finansiering fra tradisjonelle institusjoner.`}</p>
    <h3>{`Regionalt investeringsfond`}</h3>
    <p>{`Hvordan legger vi forholdene til rette og sikrer oss lokal verdiskaping innen teknologi
og at denne forblir i regionen? Vi mener det må på plass et regionalt fond hvor det
investeres i oppstarts- og vekstbedrifter som jobber med utvikling av programvare og
teknologi. Selskapene det skal investeres i bør utvikle produkter og teknologi som er
repeterbar, dvs. den skal kunne installeres og tas i bruk flere ganger uten
produksjonskostnader. Videre bør et slikt selskap ha et potensielt marked som strekker
seg langt utover Norges grenser. Et slikt fond bør være i størrelsesorden 150-250
millioner og kompetanse til å vurdere og forvalte investeringer. På denne måten kan man
ha 15-20 selskaper under aktiv forvaltning med en tidshorisont på 5-8 år per investering.`}</p>
    <h3>{`Utbyggingen av fiber gir oss fantastiske muligheter`}</h3>
    <p>{`Pandemien har vist oss at vi ikke trenger å bo der man jobber, eller jobbe der man bor.
Trailguide har sine ansatte henholdsvis i Gol, Ål, Sogndal, Oslo og Alicante. For oss har
det vært helt avgjørende med den omfattende fiberutbyggingen i regionen. God tilgang til
Internett er avgjørende for samarbeid, kursing, opplæring og utdanning i dagens digitale
hverdag, som ofte er basert på høykvalitets lyd og video. I Trailguide har dette
eksempelvis lagt til rette for at vi kan jobbe effektivt over video med rask overføring
og responstid når vi driver med såkalt "par-programmering". Legger man til rette og
skaper et godt miljø for teknologiarbeiderne, så kommer de til fjells. Her strekker
pengene lengre, fritidstilbudene ligger som kjent rett utenfor dørstokken og jobben har
de med seg. Men, vi må også våge å utvide bolyst-pakka med muligheten til å utvikle
arbeidsplasser innen teknologi lokalt. Løsningen vår på denne problemstillingen har vi
allerede skissert over, spørsmålet er vel heller om vi som region er moden for å ta den
risikoen?`}</p>
    <br />
    <p>{`Rotneim, Hallingdal`}</p>
    <p>{`6 mai 2022`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      